@import 'mixins';
.icon {
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
}
.icon-16 {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.icon-18 {
  @extend .icon;
  width: 18px;
  height: 18px;
  background-size: 18px;
}

.icon-20 {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-size: 20px;
}

.icon-24 {
  @extend .icon;
  width: 24px;
  height: 24px;
  background-size: 24px;
}
.icon-30 {
  @extend .icon;
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.icon-app-bar-logo {
  background: linear-gradient(180deg, #fe6b8b 30%, #ff8e53 90%);
  border-radius: 50%;
}
.icon-app-bar-cart {
  background-image: url('./../../assets/icons/icon-app-bar-cart.svg');
}
.icon-app-bar-category {
  background-image: url('./../../assets/icons/icon-app-bar-category.svg');
}
.icon-app-bar-category-unselected {
  background-image: url('./../../assets/icons/icon-app-bar-category-unselected.svg');
}
.icon-app-bar-account {
  background-image: url('./../../assets/icons/icon-app-bar-account.svg');
}
.icon-app-bar-account-unselected {
  background-image: url('../../assets/icons/icon-app-bar-account-unselected.svg');
}
.icon-back-bar-back {
  background-image: url('./../../assets/icons/icon-back-bar-back.svg'),
    url('./../../assets/icons/icon-back-bar-back.png');
  &.right-arrow {
    transform: rotate(180deg);
  }
}
.icon-back-bar-back-red {
  background-image: url('./../../assets/icons/icon-back-bar-back-red.svg'),
    url('./../../assets/icons/icon-back-bar-back-red.png');
  &.right-arrow {
    transform: rotate(180deg);
  }
}
.icon-back-bar-back-white {
  background-image: url('./../../assets/icons/icon-back-bar-back-white.svg');
}
.icon-back-bar-cart {
  background-image: url('./../../assets/icons/icon-back-bar-cart.svg'),
    url('./../../assets/icons/icon-back-bar-cart.png');
}
.icon-back-bar-search {
  background-image: url('./../../assets/icons/icon-back-bar-search.svg'),
    url('./../../assets/icons/icon-back-bar-search.png');
}
.icon-location-bar-location-marker {
  background-image: url('./../../assets/icons/icon-location-bar-location-marker.svg');
}
.icon-location-bar-offers {
  background-image: url('./../../assets/icons/icon-location-bar-offers.svg'),
    url('./../../assets/icons/icon-location-bar-offers.png');
}
.icon-location-bar-bell {
  background-image: url('./../../assets/icons/icon-location-bar-bell.svg'),
    url('./../../assets/icons/icon-location-bar-bell.png');
}
.icon-common-close {
  background-image: url('./../../assets/icons/icon-common-close.svg'),
    url('./../../assets/icons/icon-common-close.png');
}
.icon-common-close-white {
  background-image: url('./../../assets/icons/icon-common-close-white.svg');
}
.icon-location-crosshair {
  background-image: url('./../../assets/icons/icon-location-crosshair.png');
}
.icon-common-plus {
  background-image: url('./../../assets/icons/icon-common-plus.svg'),
    url('./../../assets/icons/icon-common-plus.png');
}
.icon-common-minus {
  background-image: url('./../../assets/icons/icon-common-minus.svg'),
    url('./../../assets/icons/icon-common-minus.png');
}
.icon-common-white-arrow-right {
  background-image: url('./../../assets/icons/icon-common-white-arrow.svg');
  @include arrow-directions();
}
.icon-common-red-arrow-right {
  background-image: url('./../../assets/icons/icon-common-red-arrow.svg');
  @include arrow-directions();
}
.icon-common-black-arrow-right {
  background-image: url('./../../assets/icons/icon-common-black-arrow.svg');
  @include arrow-directions();
}
.icon-common-green-arrow-right {
  background-image: url('./../../assets/icons/icon-common-green-arrow.svg');
}
.icon-common-yellow-arrow-right {
  background-image: url('./../../assets/icons/icon-common-yellow-arrow.svg');
}
.icon-payment-currency-note {
  background-image: url('./../../assets/icons/icon-payment-currency-note.svg');
}
.icon-payment-pod {
  background-image: url('./../../assets/icons/icon-payment-pod.svg');
}
.icon-payment-dummy-card {
  background-image: url('./../../assets/icons/icon-payment-dummy-card.svg');
}
.icon-common-checkbox {
  &-selected {
    background-image: url('./../../assets/icons/icon-common-checkbox-selected.svg');
  }
  &-unselected {
    background-image: url('./../../assets/icons/icon-common-checkbox-unselected.svg');
  }
}
.icon-common-white-bg-checkbox {
  &-selected {
    background-image: url('./../../assets/icons/icon-common-white-bg-checkbox-selected.svg');
  }
  &-unselected {
    background-image: url('./../../assets/icons/icon-common-white-bg-checkbox-unselected.svg');
  }
}

.icon-common-radio {
  &-selected {
    background-image: url('./../../assets/icons/icon-common-radio-selected.svg');
  }
  &-unselected {
    background-image: url('./../../assets/icons/icon-common-radio-unselected.svg');
  }
}

.icon-shipment1 {
  &-active,
  &-done {
    background-image: url('./../../assets/icons/icon-shipment1-active.svg');
  }
}
.icon-shipment2 {
  &-inactive {
    background-image: url('./../../assets/icons/icon-shipment2-inactive.svg');
  }
  &-active,
  &-done {
    background-image: url('./../../assets/icons/icon-shipment2-active.svg');
  }
}

.icon-shipment3 {
  &-inactive {
    background-image: url('./../../assets/icons/icon-shipment3-inactive.svg');
  }
  &-active,
  &-done {
    background-image: url('./../../assets/icons/icon-shipment3-active.svg');
  }
}
.icon-shipment4 {
  &-inactive {
    background-image: url('./../../assets/icons/icon-shipment4-inactive.svg');
  }
  &-active,
  &-done {
    background-image: url('./../../assets/icons/icon-shipment4-active.svg');
  }
}

.icon-nav-bar-account {
  background-image: url('./../../assets/icons/icon-nav-bar-accounts.svg');
}

.icon-order-box-coins {
  background-image: url('./../../assets/icons/icon-order-box-coins.svg');
}

.icon-order-box-coins-no-shadow {
  background-image: url('./../../assets/icons/icon-order-box-coins-no-shadow.svg');
}

.icon-payment-mastercard {
  background-image: url('./../../assets/icons/icon-payment-mastercard.svg');
}
.icon-payment-visa {
  background-image: url('./../../assets/icons/icon-payment-visa.svg');
}
.icon-payment-bhim {
  background-image: url('./../../assets/icons/icon-payment-bhim.svg');
}
.icon-payment-gpay {
  background-image: url('./../../assets/icons/icon-payment-gpay.svg');
}
.icon-payment-phonepe {
  background-image: url('./../../assets/icons/icon-payment-phonepe.svg');
}
.icon-payment-rupay {
  background-image: url('./../../assets/icons/icon-payment-rupay.svg');
}

.icon-payment-card {
  &-mastercard {
    background-image: url('./../../assets/icons/icon-payment-card-mc.svg');
  }
  &-visa {
    background-image: url('./../../assets/icons/icon-payment-card-visa.svg');
  }
  &-rupay {
    background-image: url('./../../assets/icons/icon-payment-card-rupay.svg');
  }
  &-amex {
    background-image: url('./../../assets/icons/icon-payment-card-amex.svg');
  }
}

.icon-payment-vpa {
  &-upi {
    background-image: url('./../../assets/icons/icon-payment-vpa-upi.svg');
  }
  &-gpay {
    background-image: url('./../../assets/icons/icon-payment-vpa-gpay.jpg');
  }
  &-apay {
    background-image: url('./../../assets/icons/icon-payment-vpa-apay.jpg');
  }
}
.icon-payment-wallet {
  &-generic {
    background-image: url('./../../assets/icons/icon-payment-wallet-generic.svg');
  }
  &-paytm {
    background-image: url('./../../assets/icons/icon-payment-wallet-paytm.jpg');
  }
  &-amazonpay {
    background-image: url('./../../assets/icons/icon-payment-wallet-amazonpay.jpg');
  }
  &-freecharge {
    background-image: url('./../../assets/icons/icon-payment-wallet-freecharge.jpg');
  }
  &-payzapp {
    background-image: url('./../../assets/icons/icon-payment-wallet-payzapp.jpg');
  }
  &-phonepe {
    background-image: url('./../../assets/icons/icon-payment-wallet-phonepe.jpg');
  }
}

.icon-payment-bnpl {
  &-lazypay {
    background-image: url('./../../assets/bank-logos/lazypay.png');
  }
  &-mobizip {
    background-image: url('./../../assets/bank-logos/mobizip.png');
  }
  &-default {
    background-image: url('./../../assets/bank-logos/default.png');
  }
}

.icon-home-selected {
  background-image: url('./../../assets/app-icons/app-icon-primary-bg.svg');
}
.icon-home-unselected {
  background-image: url('./../../assets/app-icons/app-icon-white-bg.png');
}
.icon-cart-empty-cart {
  background-image: url('./../../assets/icons/icon-cart-empty-cart.svg');
}
.icon-payment-home {
  background-image: url('./../../assets/icons/icon-payment-home.svg');
}
.icon-payment-rupee {
  background-image: url('./../../assets/icons/icon-payment-rupee.svg');
}
.icon-payment-wallet {
  background-image: url('./../../assets/icons/icon-payment-wallet.svg');
}
.icon-payment-wallet-white-bg {
  background-image: url('./../../assets/icons/icon-payment-wallet-white-bg.svg');
}
.icon-payment-halalbox-money {
  background-image: url('./../../assets/icons/icon-payment-halalbox-money.svg');
}
.icon-hb-money-bg {
  background-image: url('./../../assets/icons/icon-hb-money-bg.svg');
}
.icon-hb-money-wallet {
  background-image: url('./../../assets/icons/icon-hb-money-wallet.svg');
}
.icon-hb-money-faster-checkout {
  background-image: url('./../../assets/icons/icon-hb-money-faster-checkout.svg');
}
.icon-wallet-transaction-debit {
  background-image: url('./../../assets/icons/icon-wallet-transaction-debit.svg');
}
.icon-wallet-transaction-pending {
  background-image: url('./../../assets/icons/icon-wallet-transaction-pending.svg');
}
.icon-wallet-transaction-credit {
  background-image: url('./../../assets/icons/icon-wallet-transaction-credit.svg');
}
.icon-wallet-transaction-failed {
  background-image: url('./../../assets/icons/icon-wallet-transaction-failed.svg');
}
.icon-payment-gift {
  background-image: url('./../../assets/icons/icon-payment-gift.svg');
}
.icon-payment-faqs {
  background-image: url('./../../assets/icons/icon-payment-faqs.svg');
  &-white {
    background-image: url('./../../assets/icons/icon-payment-faqs-white.svg');
  }
}
.icon-payment-tnc {
  background-image: url('./../../assets/icons/icon-payment-tnc.svg');
}
.icon-payment-policy {
  background-image: url('./../../assets/icons/icon-payment-policy.svg');
}
.icon-accounts-logout {
  background-image: url('./../../assets/icons/icon-accounts-logout.svg');
}
.icon-referral-coupon-bg {
  background-image: url('./../../assets/icons/icon-referral-coupon-bg.svg');
}
.icon-referral-info {
  background-image: url('./../../assets/icons/icon-referral-info.svg');
}
.icon-referral-info-yellow {
  background-image: url('./../../assets/icons/icon-referral-info-yellow.svg');
}
.icon-info-yellow {
  background-image: url('./../../assets/icons/icon-info-yellow.svg');
}
.icon-referral-whatsapp {
  background-image: url('./../../assets/icons/icon-referral-whatsapp.svg');
}
.icon-whatsapp-flat {
  background-image: url('./../../assets/icons/icon-whatsapp-flat.svg');
}
.icon-referral-fb-messenger {
  background-image: url('./../../assets/icons/icon-referral-fb-messenger.svg');
}
.icon-referral-sms {
  background-image: url('./../../assets/icons/icon-referral-sms.svg');
}
.icon-referral-fb {
  background-image: url('./../../assets/icons/icon-referral-fb.svg');
}
.icon-referral-copy {
  background-image: url('./../../assets/icons/icon-referral-copy.svg');
}
.icon-shipment-done-tick {
  background-image: url('./../../assets/icons/icon-shipment-done-tick.svg');
}
.icon-cart-promo-sample {
  background-image: url('./../../assets/icons/icon-cart-promo-sample.svg');
}
.icon-order-shipment-box {
  background-image: url('./../../assets/icons/icon-order-shipment-box.svg');
}
.icon-status-call-delivery-boy {
  background-image: url('./../../assets/icons/icon-status-call-delivery-boy.svg');
}
.icon-status-call-green {
  background-image: url('./../../assets/icons/icon-status-call-green.svg');
}
.icon-status-arrival {
  background-image: url('./../../assets/icons/icon-status-arrival.svg');
}
.icon-status-delivered {
  background-image: url('./../../assets/icons/icon-status-delivered.svg');
}
.icon-status-order-active,
.icon-status-order-present {
  background-image: url('./../../assets/icons/icon-status-order-active.svg');
}
.icon-status-order-done,
.icon-status-order-past {
  background-image: url('./../../assets/icons/icon-status-order-done.svg');
}
.icon-status-order-inactive,
.icon-status-order-future {
  background-image: url('./../../assets/icons/icon-status-order-inactive.svg');
}
.icon-status-order-packed {
  background-image: url('./../../assets/icons/icon-status-order-packed.svg');
}
.icon-status-order-cancelled {
  background-image: url('./../../assets/icons/icon-status-order-cancelled.svg');
}
.icon-status-order-refunded {
  background-image: url('./../../assets/icons/icon-status-order-refunded.svg');
}
.icon-status-order-returned {
  background-image: url('./../../assets/icons/icon-status-order-returned.svg');
}
.icon-status-returned {
  background-image: url('./../../assets/icons/icon-status-returned.svg');
}
.icon-status-refunded {
  background-image: url('./../../assets/icons/icon-status-refunded.svg');
}
.icon-status-out-for-delivery {
  background-image: url('./../../assets/icons/icon-status-out-for-delivery.svg');
}
.icon-status-pickup-assigned {
  background-image: url('./../../assets/icons/icon-status-pickup-assigned.svg');
}
.icon-cart-remove-item {
  background-image: url('./../../assets/icons/icon-cart-remove-item.svg');
}
.icon-arrow-scroll {
  background-image: url('./../../assets/icons/scroll-white-arrow.svg');
  &.right-arrow {
    transform: rotate(180deg);
  }
}
.icon-cart-on-sale {
  background-image: url('./../../assets/icons/icon-cart-on-sale.svg');
}
.icon-empty-notifications {
  background-image: url('./../../assets/icons/icon-empty-notifications.svg');
}
.icon-empty-offers {
  background-image: url('./../../assets/icons/icon-empty-offers.svg');
}
.icon-orders-bag {
  background-image: url('../../assets/icons/icon-orders-bag.svg');
}
.icon-referral-earned {
  background-image: url('./../../assets/icons/icon-referral-earned.svg');
}
.icon-referral-pending {
  background-image: url('./../../assets/icons/icon-referral-pending.svg');
}
.icon-payment-cvv {
  background-image: url('./../../assets/icons/icon-payment-cvv.svg');
}
.icon-cart-shipment-status {
  background-image: url('./../../assets/icons/icon-cart-shipment-status.svg');
}
.icon-location-marker-shipment {
  background-image: url('./../../assets/icons/icon-location-marker-shipment.svg');
}
.icon-shipment-bag {
  background-image: url('./../../assets/icons/icon-shipment-bag.svg');
}
.icon-gmail {
  background-image: url('./../../assets/icons/icon-gmail.svg');
}
.icon-customer-care {
  background-image: url('./../../assets/icons/icon-customer-care.svg');
}
.icon-instagram {
  background-image: url('./../../assets/icons/icon-instagram.svg');
}
.icon-facebook {
  background-image: url('./../../assets/icons/icon-facebook.svg');
}
.icon-common-zoom-lens {
  background-image: url('./../../assets/icons/icon-common-zoom-lens.svg');
}
.icon-subscription-tick {
  background-image: url('./../../assets/icons/icon-subscription-tick.svg');
}
.icon-app-bar-subscription {
  background-image: url('./../../assets/icons/icon-app-bar-subscription.svg');
}
.icon-subscription-calendar {
  background-image: url('./../../assets/icons/icon-subscription-calendar.svg');
}
.icon-subscription-cart {
  background-image: url('./../../assets/icons/icon-subscription-cart.svg');
}
.icon-common-green-tick {
  background-image: url('./../../assets/icons/icon-common-green-tick.svg');
}
.icon-common-red-exclamation {
  background-image: url('https://ik.imagekit.io/iwcam3r8ka/prod/misc/202106/48d2c550-8a61-43ef-aca9-e948e8fb2f26.png');
}
.icon-tada {
  background-image: url('./../../assets/icons/icon-tada.svg');
}

.icon-new-green-bg {
  //background-image: url('./../../assets/icons/icon-new-green-bg.svg');
  background-image: linear-gradient(104deg, $green400 9%, $green600 86%);
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
}

.icon-hb-point-icon {
  background-image: url('./../../assets/icons/icon-hbpoints.svg');
}
.icon-clock-red {
  background-image: url('./../../assets/icons/icon-clock-red.svg');
}
.icon-pre-order {
  background-image: url('../../assets/pre-order.svg');
}
.hb-prime-remove-icon {
  background-image: url('../../assets/halalbox-prime/remove-icon.svg');
}
.icon-hb-prime-checkbox-check {
  background-image: url('../../assets/halalbox-prime/checkbox-check.svg');
}
.icon-hb-prime-checkbox-uncheck {
  background-image: url('../../assets/halalbox-prime/checkbox-uncheck.svg');
}

.icon-hb-prime-banner {
  background-image: url('../../assets/halalbox-prime/hb-prime-banner-icon.png');
}
.icon-multiple-sku-dropdown {
  background-image: url('./../../assets/icons/red-arrow.svg');
}
