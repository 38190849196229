.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Overriding link style so as to
// make custom style wherever required.
a {
  text-decoration: none;
  color: $black;
  &:active,
  &:hover {
    color: $black;
  }
}

.pull {
  &-left {
    float: left;
  }
  &-right {
    float: right;
  }
}

.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
}

.justify-content--space-between {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.bottom-sticky-cta {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: $background-gray;
  padding: 15px 30px;
}

.std-margin-all {
  margin: $standard-margin;
}
.std-margin-tb {
  margin: $standard-margin 0;
}
.std-margin-lr {
  margin: 0 $standard-margin;
}

.section-divider {
  height: 10px;
  background-color: $background-gray;
  &--white {
    height: 10px;
  }
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.position {
  &--relative {
    position: relative;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.is-desktop {
  .hide-for-desktop {
    display: none !important;
  }
}

.desktop-container {
  .section-divider {
    height: 10px;
    background-color: $background-gray;
    margin: 40px 0;
    &--white {
      height: 15px;
    }
  }
}
